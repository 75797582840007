<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="名称" prop="name" :rules="rules">
        <el-input style="width: 400px" v-model="formData.name" size="small" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="封面图">
        <UploadImgs :background_images.sync="formData.background_images"></UploadImgs>
      </el-form-item>
      <el-form-item label="位置">
        <div class="position">
          <div class="input">
            <AreaTree v-model="addressDesc" :disabled="formData.address ? true : false" width="200px" :keyword="keyword"></AreaTree>
            <el-input
              :disabled="!keyword"
              style="width: 200px; margin-left: 20px"
              v-model="formData.address"
              size="small"
              placeholder="请输入详细地址"
              clearable
            ></el-input>
            <span class="tip">（注意：输入详细地址后点击地图确定坐标！）</span>
          </div>
          <div class="map">
            <Map :lng.sync="formData.longitude" :lat.sync="formData.latitude" :addressDesc="addressDesc" :address="formData.address"></Map>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item label="状态">
        <el-select style="width: 400px" v-model="formData.status" placeholder="请选择" size="small">
          <el-option v-for="item in charge_station_status" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="支持预约">
        <el-select style="width: 400px" v-model="formData.is_reservable" placeholder="请选择" size="small">
          <el-option v-for="item in dict_is_reservable" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="限时免停">
        <el-select style="width: 400px" v-model="formData.is_limited_free" placeholder="请选择" size="small">
          <el-option v-for="item in dict_is_limited_free" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="充电限时免停">
        <el-select style="width: 400px" v-model="formData.is_charging_limited_free" placeholder="请选择" size="small">
          <el-option v-for="item in dict_is_charging_limited_free" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="占位费描述">
        <el-input style="width: 400px" type="textarea" :rows="4" v-model="formData.occupy_fee.describe" size="small" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="停车场说明">
        <el-input style="width: 400px" type="textarea" :rows="4" v-model="formData.parking_intro" size="small" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { getUploadConfigApi } from '@/components/uploader/api.js'
import UploadImgs from '@/components/uploader/uploader-imgs.vue'
import AreaTree from '@/views/components/select-area.vue'
import Map from '@/components/map/map.vue'
import { addStationAPI, getStationDetailAPI, editStationAPI } from './api'
import { getDictEnumListAPI, dict_is_reservable, dict_is_free_open, dict_is_limited_free, dict_is_anti_occupy, dict_is_charging_limited_free } from '@/enum/dict.js'
export default {
  name: 'AddOrEdit',
  components: { Map, AreaTree, UploadImgs },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        name: '',
        longitude: '',
        latitude: '',
        status: '',
        province: '',
        city: '',
        district: '',
        address: '',
        background_images: [],
        is_reservable: '',
        is_limited_free: '',
        is_charging_limited_free: '',
        occupy_fee: {
          describe: ''
        },
        parking_intro: ''
      },
      addressDesc: [],
      charge_station_status: [],
      dict_is_reservable,
      dict_is_limited_free,
      dict_is_charging_limited_free
    }
  },
  computed: {
    keyword() {
      return this.addressDesc.reduce((total, item) => total + item, '')
    }
  },
  mounted() {
    this.getDictEnumList()
    if (this.$route.params.id) this.getStationDetail()
  },

  methods: {
    save() {
      // this.formData.background_images = JSON.stringify(this.formData.background_images)
      this.formData.province = this.addressDesc[0] || ''
      this.formData.city = this.addressDesc[1] || ''
      this.formData.district = this.addressDesc[2] || ''
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editStationAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addStationAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    // 字典
    async getDictEnumList() {
      const res = await getDictEnumListAPI('charging_station_status')
      this.charge_station_status = res.map((item) => {
        return {
          name: item.name,
          value: item.value - 0
        }
      })
    },
    // 详情
    async getStationDetail() {
      const res = await getStationDetailAPI(this.$route.params.id)
      this.formData = res
      // this.formData.background_images = []
      // this.$set(this.formData, 'background_images', JSON.parse(res.background_images))
      // this.addressDesc = [this.formData.province, this.formData.city, this.formData.district]
      const { province, city, district } = res
      if (province) {
        this.addressDesc.push(province)
      }
      if (city) {
        this.addressDesc.push(city)
      }
      if (district) {
        this.addressDesc.push(district)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  .position {
    .map {
      width: 800px;
      height: 300px;
    }
  }
  .tip {
    margin-left: 20px;
    color: #c0c4cc;
  }
}
</style>
