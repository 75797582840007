<template>
  <div>
    <div ref="mapContainer" class="map-container"></div>
  </div>
</template>

<script>
import Marker_blue from '@/assets/icon/marker_blue.png'
export default {
  name: 'Map',
  props: {
    lat: {
      type: [String, Number],
      default: ''
    },
    lng: {
      type: [String, Number],
      default: ''
    },
    addressDesc: {
      type: Array,
      default: () => []
    },
    address: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      map: null,
      markerLayer: null,
      longitude: 116.397128,
      latitude: 39.916527,
      search: null,
      suggest: null
    }
  },

  mounted() {
    this.initMap()
    // this.searchByKeyword()
  },
  computed: {
    keyword() {
      return this.addressDesc.reduce((total, item) => total + item, '')
    }
  },
  watch: {
    keyword: {
      handler(newV, oldV) {
        // if (this.$route.params.id) return
        if (newV) {
          if (newV && !this.address) {
            this.searchByKeyword(this.addressDesc[1], newV)
          }
        } else if (!newV && !this.address) {
          this.latitude = 39.916527
          this.longitude = 116.397128
          this.map.setCenter(new TMap.LatLng(this.latitude, this.longitude))
        }
      }
    },
    address: {
      handler(newV, oldV) {
        if (this.$route.params.id) return
        if (newV) {
          this.searchByKeyword(this.addressDesc[1], this.keyword + newV)
        } else {
          this.searchByKeyword(this.addressDesc[1], this.keyword)
        }
      }
    },
    lat: {
      handler(newV, oldV) {
        if (newV) {
          this.markerLayer.add({
            position: new TMap.LatLng(this.lat, this.lng),
            styleId: 'marker'
          })
          this.map.setCenter(new TMap.LatLng(this.lat, this.lng))
        }
      }
    }
  },
  methods: {
    initMap() {
      const center = new TMap.LatLng(39.984104, 116.307503)
      //初始化地图
      this.map = new TMap.Map(this.$refs.mapContainer, {
        rotation: 20, //设置地图旋转角度
        pitch: 30, //设置俯仰角度（0~45）
        zoom: 15, //设置地图缩放级别
        center: center //设置地图中心点坐标
      })
      this.initMarker()

      this.map.on('click', e => {
        const { lat, lng } = e.latLng
        this.$emit('update:lat', lat)
        this.$emit('update:lng', lng)
        this.markerLayer.setMap(null)
        this.markerLayer = null
        this.initMarker()
        if (this.markerLayer) {
          this.markerLayer.add({
            position: e.latLng,
            styleId: 'marker'
          })
        }
      })
    },
    initMarker() {
      //初始化marker图层
      this.markerLayer = new TMap.MultiMarker({
        id: 'marker-layer',
        map: this.map,
        styles: {
          marker: new TMap.MarkerStyle({
            width: 27,
            height: 34,
            anchor: { x: 16, y: 32 },
            src: Marker_blue
          })
        }
      })
    },
    searchByKeyword(region, kw) {
      this.search = new TMap.service.Search({ pageSize: 10 })
      this.suggest = new TMap.service.Suggestion({
        pageSize: 10, // 返回结果每页条目数
        region: region, // 限制城市范围
        regionFix: true // 搜索无结果时是否固定在当前城市
      })
      this.suggest.getSuggestions({ keyword: kw, location: this.map.getCenter() }).then(res => {
        const { location } = res.data[0]
        this.latitude = location.lat
        this.longitude = location.lng
        this.map.setCenter(new TMap.LatLng(this.latitude, this.longitude))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 300px;
}
</style>
