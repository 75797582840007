<template>
  <div class="container">
    <template v-if="uploadConf.qiniu_upload">
      <el-upload
        :action="uploadConf.qiniu_upload"
        :data="uploadConf"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :on-success="handleAvatarSuccess"
        :on-error="handleError"
        :before-upload="beforeAvatarUpload"
        :file-list="bgList"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </template>
  </div>
</template>

<script>
import { getUploadConfigApi } from './api'
import { nanoid } from 'nanoid'
export default {
  name: 'UploaderFile',
  data() {
    return {
      uploadType: '',
      uploadConf: {},
      dialogImageUrl: '',
      dialogVisible: false,
      bgList: []
    }
  },

  props: {
    background_images: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    background_images: {
      handler(newV, oldV) {
        if (newV) {
          this.bgList = newV
        }
      }
    }
  },
  created() {
    this.getUploadConfig()
  },
  mounted() {},
  methods: {
    async getUploadConfig() {
      const res = await getUploadConfigApi()
      this.uploadType = res.uploadType
      this.uploadConf = res.config
    },

    beforeAvatarUpload: function (file) {
      this.getUploadConfig()
      let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1)
      this.uploadConf.key = nanoid() + '.' + fileExtension
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG && !isPNG) {
        this.$message.error('图片只能是 JPG/PNG 格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!')
        return false
      }
    },
    handleAvatarSuccess: function (res, file) {
      const { name, uid } = file
      let url = this.uploadConf.domain + '/' + res.key
      this.bgList.push({ name, url, uid })
      this.$emit('update:background_images', this.bgList)
    },
    handleError: function (res) {
      this.$message.error({
        message: '上传失败',
        duration: 2000,
        type: 'warning'
      })
    },
    handleRemove(file, fileList) {
      const { uid } = file
      this.bgList = this.bgList.filter((item) => uid != item.uid)
      this.$emit('update:background_images', this.bgList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.container /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px !important;
    height: 150px !important;
    line-height: 150px !important;
    text-align: center;
  }
  .avatar {
    width: 150px !important;
    height: 150px !important;
    display: block;
  }
}
</style>
